import api from '@/api/about'
import {VueEditor} from "vue2-editor";
// import {notify} from "@/helpers/notifyHelper";

export default {
	data () {
		return {
			form: {
				title: '',
				description: '',
			},
			images: null
		}
	},
	created() {
		this.getData()
	},
	components: {
		VueEditor
	},
	methods: {
		clearFile(name) {
			this[name] = null
		},
		getData() {
			api.get(this)
				.then(response => {
					if (response.data.length) {
						this.form = response.data[0]
					}
				})
		},
		save () {
			let data = new FormData()
			Object.keys(this.form).map(key => {
				data.append(key, this.form[key])
			})

			if (this.images) {
				for (let i = 0; i < this.images.length; i++) {
					data.append('images', this.images[i]);
				}
			}

			api.update(this, this.form.id, data)
		}
	}
}
